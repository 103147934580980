::-webkit-scrollbar {
	height: 5px;
	width: 5px;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb {
	background: #666;
	border-radius: 10px;
}
:-webkit-scrollbar-track {
	background: #ddd;
	border-radius: 10px;
}

.video-uploading {
	position: relative;
	display: inline-block;
}

.video-uploading video {
	max-width: 98% !important;
	filter: blur(5px);
	opacity: 0.3;
}

.video-uploading::before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 30px;
	height: 30px;
	margin-top: -15px;
	margin-left: -15px;
	border-radius: 50%;
	border: 3px solid #ccc;
	border-top-color: #1e986c;
	z-index: 1;
	animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

.image-uploading {
	position: relative;
	display: inline-block;
}

.image-uploading img {
	max-width: 98% !important;
	filter: blur(5px);
	opacity: 0.3;
}

.image-uploading::before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 30px;
	height: 30px;
	margin-top: -15px;
	margin-left: -15px;
	border-radius: 50%;
	border: 3px solid #ccc;
	border-top-color: #1e986c;
	z-index: 1;
	animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}
